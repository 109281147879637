import React, { useReducer } from "react";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./result.scss"

import {
  FormControl,
  Input,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Button,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import {
  CompareArrow,SearchIcon
} from "../../../../assets/icons/Icons";

import { RESULT_COMPARE_SCENARIO_DATA, RESULT_REPORT, RESULT_TABLE_DATA } from "../../../../assets/data/json";
import { Services } from "../../../shared/services";
import CustomTable from "../customTable/custome-table";
import { ITableApiResponseBody } from "../../../../utils/interfaces/interface";
import { CompareResult } from "../compare-result/compare-result";
import { useTrainer } from "../../services/trainer.service";
import { CompareScenario } from "../compare-scenario/compare-scenario";
import { CompareReport } from "../compare-report/compare-report";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ApiEndPoints } from "../../../../utils/enums/apiendpoints";
// import dayjs from 'dayjs';

export const ResultScreen = () => {
  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const [resultTableData, setResultTabledata] = React.useState<
    ITableApiResponseBody | undefined
  >();
  const [filteredTableData, setFilteredTabledata] = React.useState<
    ITableApiResponseBody | undefined
  >();
  const [isFiltered, setIsFiltered] = React.useState<Boolean>(false);
  const [status, setStatus] = React.useState<string>("None");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const trainer = useTrainer();
  const [isCompareScenarioOpen, setIsCompareScenariOpen] = useState(false);
  const [compareScenariodata, setCompareScenariodata] = useState()
  const [isReportOpen, setIsreportOpen] = useState(false);
  const [reportData, setReportData] = useState();
  const [selectedExperimentIds, setSelectedExperimentIds] = useState<number[]>([]);
  const filterData: any = {
    experimentName: '',
    status: 'None'
  };


  React.useEffect(() => {
  //  console.log('dataset name: ',trainer.datasetName)
    // if (trainer.datasetName == '') {
    //   navigate('/load-data')
    // } else {
    //   getResultTableData();
    // }
    if (trainer.datasetName !== '') {
      getResultTableData();
    }

  },[trainer.datasetName])


  const requestSearch = (searchedVal: string) => {
    filterData['experimentName'] = searchedVal
    // handleFilter()
    setIsFiltered(true);
    if (!searchedVal || !searchedVal.length) {
      setFilteredTabledata(resultTableData);
    } else {
      if (resultTableData && resultTableData.data) {
        const filteredRows = resultTableData.data.table_data.filter((row) =>
          row.experimentName.toLowerCase().includes(searchedVal.toLowerCase())
        );

        setFilteredTabledata({
          ...resultTableData,
          data: {
            ...resultTableData.data,
            table_data: filteredRows,
          },
        });
      }
    }
  };

  const getResultTableData = async () => {
    setResultTabledata(undefined)
    setFilteredTabledata(undefined)
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.RESULT_TABLE.toString() + `/${trainer.datasetName}`,
      payload: {
        dataset_name: trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title,
      },
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: RESULT_TABLE_DATA,
      successCb: (res: any) => {
        if (res?.data?.table_headers) {
          const len = res.data.table_headers.length;
          const extraColumn = {
            id: len + 1,
            name: "",
            key: "action",
            hidden: false,
            isSort: false,
            type: "resultAction",
          };
          res.data.table_headers.push(extraColumn);
        }
        res.data.table_data.sort((a: any,b: any) => {return (new Date(b.runOn) as any) - (new Date(a.runOn) as any)})
        setResultTabledata(res);
        setFilteredTabledata(res)
      },
      failedCb: (res: any) => { },
    });
  };

  const getResultCompareSenario = async (experimentIds: number[]) => {
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.RESULT_COMPARE_SCENARIOS.toString(),
      payload: {experiment_ids: experimentIds},
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: RESULT_COMPARE_SCENARIO_DATA,
      successCb: (res: any) => {
        setCompareScenariodata(res?.data);
      },
      failedCb: (res: any) => { },
    });
  };

  const getReportData = async () => {
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.RESULT_GENERATE_REPORT.toString(),
      payload: {experiment_ids: selectedExperimentIds},
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: RESULT_REPORT,
      successCb: (res: any) => {
        setReportData(res?.data);
      },
      failedCb: (res: any) => { },
    });
  };

  const handleFilter = () => {
  //  console.log(filterData)
    if (resultTableData && resultTableData.data) {
      const filteredRows = resultTableData.data.table_data.filter((row) =>{
      //  console.log(
        //   (filterData['experimentName'].length ? row.experimentName.toLowerCase().includes(filterData['experimentName']?.toLowerCase()) : true) && row.status.toLowerCase().includes(filterData['status']?.toLowerCase()), 
        //   filterData['experimentName'].length ? row.experimentName.toLowerCase().includes(filterData['experimentName']?.toLowerCase()) : true,
        //   row.status.toLowerCase().includes(filterData['status']?.toLowerCase())
        // )
        return  row.experimentName.toLowerCase().includes(filterData['experimentName']?.toLowerCase()) && 
                row.status.toLowerCase().includes(filterData['status']?.toLowerCase())
        }
      );

      setFilteredTabledata({
        ...resultTableData,
        data: {
          ...resultTableData.data,
          table_data: filteredRows,
        },
      });
    }
  }

  const handleStatusChange = (value: any) => {
    filterData['status'] = value == 'None' ? '' : value
    // handleFilter()
    setIsFiltered(true);
    setStatus(value);
    if (value === "None") {
      setFilteredTabledata(resultTableData);
    } else {
      if (resultTableData && resultTableData.data) {
        const filteredRows = resultTableData.data.table_data.filter((row) =>
          row.status.toLowerCase().includes(value.toLowerCase())
        );

        setFilteredTabledata({
          ...resultTableData,
          data: {
            ...resultTableData.data,
            table_data: filteredRows,
          },
        });
      }
    }
  };
  const handleDateFilter = (date: any) => {
    console.log(date)
  }
  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const handleCompareScenarioClose = () => {
    setIsCompareScenariOpen(!isCompareScenarioOpen);
  };

  const OnCompareClick = (experimentIds:number[]) => {
  //  console.log(experimentIds);
    setSelectedExperimentIds(experimentIds)
    setIsCompareScenariOpen(true);
    getResultCompareSenario(experimentIds);
  }

  const onGenerateReportClick = (data:any) => {
    // console.log(data);
    setIsreportOpen(true);
    getReportData();
  }

  const reportClose = () => {
    setIsreportOpen(!isReportOpen);
  }

  const onDownloadreportClick = (data:any) => {
    // console.log(data);
  }

  const handleDownloadExperiment = async (row: any) => {
    try {
      AppService.downloadFile(ApiEndPoints.RESULT_DOWNLOAD_EXPERIMENT.toString() + `?process_id=${row.process_id}`).then(async response => {
        if (response.status === 403 || response.status === 401) {
          window.location.href = window.location.origin + "/login";
          // auth?.setSnackBarDetails({
          //   message: 'Session expired. Redirecting to login',
          //   type: "error",
          // });
        }
        if (response.status == 200) {
          // Extract filename from Content-Disposition header
          const contentDisposition = response.headers.get("content-disposition");
          
          const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
          
          //const suggestedFileName = filenameMatch ? filenameMatch[1] : 'experiment' + ".csv";
          const suggestedFileName = filenameMatch ? filenameMatch[1] : row.process_id ? `${row.process_id}_experiment.xlsx` : 'experiment' + ".xlsx";
          
          // Create a Blob from the response data
          const blob =  response.blob();
      
          // Create a download link
          const url = URL.createObjectURL(await blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", suggestedFileName);
          document.body.appendChild(link);
      
          // Trigger the download
          link.click();
      
          // Clean up
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
    
        
      })
    }
    catch (error) {
      console.error("Error downloading file:", error);
    }
  }

  const handleTableRowAction = (action: string, row: any) => {
    // Handle the emitted event here
    // console.log(`Received event: ${action} for row:`, row);
    switch(action) {
      case "resultView":
        trainer.setExperimentDetails(row);
        navigate(`/result/${row.experimentName}`)
        break;
      case "downloadExperiment":
        handleDownloadExperiment(row)
        break;
    }
  };

  return (
    <>
      <section className="result-page">
        <div className="d-flex justify-content-end mb-3">
          <div className="border-end pe-3 me-3">
            <div className="search-input">
              <input
                id="experiment-search"
                onChange={(event) => requestSearch(event.target.value)}
                className="form-control"
                placeholder="Search Result"
              />
              <SearchIcon />
            </div>
          </div>
          <div className="border-end pe-3 me-3 d-flex">
            <FormControl className="d-flex flex-row align-items-center">
              <Select
                labelId="status-select"
                id="status-select"
                value={status}
                onChange={(event) => {
                  handleStatusChange(event?.target.value);
                }}
                className="form-control"
              >
                <MenuItem value="None">No Selection</MenuItem>
                <MenuItem value="success">Completed</MenuItem>
                <MenuItem value="failed">Failed</MenuItem>
                <MenuItem value="in progress">In Progress</MenuItem>
              </Select>
            </FormControl>
            <div className="custom-date ms-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                    <DatePicker onChange={(dateValue) => handleDateFilter(dateValue)} />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
          <button className="compare-btn" onClick={() => getResultTableData()}>
            Refresh
          </button>
          {/* <button className="compare-btn" onClick={() => setIsOpen(true)}>
            <CompareArrow /> Compare
          </button> */}
        </div>
        <div className="table-wrapper">
          {filteredTableData ? 
            filteredTableData.data?.table_data?.length !== 0 ? (
              <>
                <CustomTable
                  data={filteredTableData}
                  onTableRowAction={(action, row) =>
                    handleTableRowAction(action, row)
                  }
                />
              </>
            ) : (
              <div className="noData-found">No Table Data</div>
            ) :
            <div className="page-loader">
              <CircularProgress />
            </div>
          }
        </div>
        <div>
          <CompareResult
            isOpen={isOpen}
            handleClose={handleClose}
            tableData={resultTableData}
            compareClick={OnCompareClick}
          />
        </div>
        <div>
          <CompareScenario
            isOpen={isCompareScenarioOpen}
            handleCompareScenarioClose={handleCompareScenarioClose}
            data={compareScenariodata}
            generateReportClick={onGenerateReportClick}
          />
        </div>
        <div>
          <CompareReport
            isOpen={isReportOpen}
            handleClose={reportClose}
            data={reportData}
            downloadClick={onDownloadreportClick}
          />
        </div>
      </section>
    </>
  );
};
