import { useState, useEffect } from "react";

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button"; // Import Button from MUI
import { ITableApiResponseBody } from "../../../../utils/interfaces/interface";
import {
  DeleteIcon,
  LoadDataDownloadIcon,
  ViewIcon,
  DownloadSchemaIcon,
  InfoIcon,
  Complete,
  Failed,
  InProgress
} from "../../../../assets/icons/Icons";
import { useTrainer } from "../../services/trainer.service";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { Services } from "../../../shared/services";
import { ApiEndPoints } from "../../../../utils/enums/apiendpoints";
import { Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";

interface CustomTableProps {
  data: ITableApiResponseBody;
  setDisplayLoader?: any;
  onTableRowAction: (action: string, row: any) => void;
}

const CustomTable: React.FC<CustomTableProps> = ({
  data,
  setDisplayLoader,
  onTableRowAction,
}) => {
  const [selectedRow, setSelectedRow] = React.useState<string | null>(null);
  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const trainer = useTrainer();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedMultiCheckboxes, setSelectedMultiCheckboxes] = useState<string[]>([]);

  useEffect(() => {
    const datasetNameRowId: string = data.data.table_data.findIndex((d: any) => d.dataset_name == trainer.datasetName) +''
    setSelectedRow(datasetNameRowId);
  }, [])

  const downloadSchema = async (key: string) => {
    try {
      setDisplayLoader(true)
      AppService.downloadFile(ApiEndPoints.DOWNLOAD_SAVED_DATASET.toString() + "?dataset_id=" + key).then(async response => {
        setDisplayLoader(false)
        if (response.status === 403 || response.status === 401) {
          window.location.href = window.location.origin + "/login";
          // auth?.setSnackBarDetails({
          //   message: 'Session expired. Redirecting to login',
          //   type: "error",
          // });
        }
        if (response.status == 200) {
          
          // Extract filename from Content-Disposition header
          const contentDisposition = response.headers.get("content-disposition");
          const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
          const suggestedFileName = filenameMatch ? filenameMatch[1] : key + ".json";
  
          // Create a Blob from the response data
          const blob = response.blob();
  
          // Create a download link
          const url = URL.createObjectURL(await blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", suggestedFileName);
          document.body.appendChild(link);
  
          // Trigger the download
          link.click();
  
          // Clean up
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }


      })
    }
    catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleRowSelect = (rowId: string) => {
    trainer.setDatasetName('');
    setSelectedRow((prevSelectedRow) =>
      prevSelectedRow === rowId ? null : rowId
    );
  };

  const handleTableRowAction = (action: string, row: any) => {
    // Call the callback function with the action and row data
    if ((action == 'resultView' || action =='downloadExperiment') && row.status != "SUCCESS") {
      return
    }
    onTableRowAction(action, row);
  };

  const handleMultiRowSelect = (value: string) => {
    handleTableRowAction("click", value)
    if (selectedMultiCheckboxes.includes(value)) {
      // If checkbox is already checked, remove it from the selection
      setSelectedMultiCheckboxes((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    } else {
      // Check if the limit of 3 checkboxes is reached
      if (selectedMultiCheckboxes.length < 3) {

        // If not, add the checkbox to the selection
        setSelectedMultiCheckboxes((prevSelected) => [...prevSelected, value]);
      } else {

        // If the limit is reached, show a warning message (you can customize this part)
        // alert("You can only select up to 3 scenarios.");
      }
    }
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow placement="left-start" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFF',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #535B62',
    },
  }));

  const renderCellContent = (
    header: any,
    row: any,
    rowIndex: number
  ): React.ReactNode => {
  //  console.log(header.type)
    switch (header.type) {
      case "text_checkbox":
        return (
          <>
            <Checkbox
              checked={
                selectedRow === rowIndex.toString() ||
                trainer.datasetName == row[header.key]
              }
              onChange={() => handleRowSelect(rowIndex.toString())}
            />
            {row[header.key]}
          </>
        );
      case "text_multi_checkbox":
        return (
          <>
            <Checkbox checked={selectedMultiCheckboxes.includes(row['experimentId'])} onChange={() => handleMultiRowSelect(row['experimentId'])} />
            {row[header.key]}
          </>
        );
      case "action":
        return (
          (selectedRow === rowIndex.toString() ||
            trainer.datasetName == row[header.key]) && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleProceed(row)}
                className="me-3"
              >
                Proceed
              </Button>
              <LoadDataDownloadIcon
                className="me-3"
                onClick={() => downloadSchema(row.dataset_id)}
              />
              <DeleteIcon onClick={() => handleTableRowAction("delete", row)} />
            </>
          )
        );
      case "resultAction":
        return (
          <>
            <Button
              className="table-icon"
              onClick={() => handleTableRowAction("resultView", row)}
            >
              <ViewIcon />
            </Button>
            <Button className="table-icon" onClick={() => handleTableRowAction('downloadExperiment',row)}>
              <DownloadSchemaIcon />
            </Button>
            {
              row?.information && row?.information?.length != 0 &&
                <HtmlTooltip
                  title={
                    <React.Fragment>
                          <div className="insight-tooltip">
                            <strong>Insight</strong>
                            <ul className="list-unstyled mb-0">
                              {row?.information?.map((info: string) => (
                                <li>{info}</li>
                              ))}
                            </ul>
                          </div>
                    </React.Fragment>
                  }
                >
                  <Button className="table-icon">
                    <InfoIcon />
                  </Button>
                </HtmlTooltip>
            }
          </>
        );
      case 'status':
        { return row['status'] === 'SUCCESS' ? <Complete /> : row['status'] === 'FAILED' ? <Failed /> : <InProgress /> }
    }
    switch (header.key) {
      case "spends":
        return (isNaN(row['spends']) ? row['spends'] : Math.round(row['spends']*100)/100).toLocaleString()
      case "incrementalSales":
        return (isNaN(row['incrementalSales']) ? row['incrementalSales'] : Math.round(row['incrementalSales']*100)/100).toLocaleString()
      default:
        return row[header.key];
    }
  };

  const handleProceed = (row: any) => {
    // Handle the "proceed" action for the row
    onTableRowAction('set_dataset', row);
  };


  return (
    <TableContainer style={{ overflowY: 'auto', maxHeight: '70vh' }}>
      <Table className="custom-table">
        <TableHead>
          <TableRow>
            
            {data.data.table_headers.map((header: any) => (
              <TableCell key={header.id}>{header.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data.table_data.map((row: any, rowIndex: number) => (
            <TableRow key={rowIndex}>
              {data.data.table_headers.map((header: any, colIndex: number) => (
                <TableCell key={colIndex}>
                  {renderCellContent(header, row, rowIndex)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
